<template>
  <component :is="currentComponent"></component>
</template>

<script>
export default {
  name: 'DemoPage',
  computed: {
    currentComponent() {
      return () => this.$platform.pageLoader(this.regulator, 'register/Demo');
    }
  }
};
</script>
